import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import UaParser from 'ua-parser-js';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import Button from '../../../util_components/button';
import { post_api, get_api } from '../../../../redux/api_funcs';
import {
  session_status_values,
  session_duration_list,
  cancelled_status,
  day_format,
  time_format
} from '../../../../constants';
import {
  check_null_text,
  check_bool_flag,
  moment_format_tz,
  image_error_replace,
  getCookie,
  isViewOnly
} from '../../../../util_functions';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomCheckBox from '../../../util_components/custom_checkbox';
import CustomInput from '../../../util_components/custom_input';
import CustomPlayer from '../../../util_components/custom_video_react';
import HighlightSearch from '../../../util_components/highlight_search_text';
import * as actions from '../../../../redux/action_creators';
import './details.css';
import { get_api_url } from '../../../../utils/urls';
import CustomRadioButton from '../../../util_components/custom_radio_button';

const cancel_options = ['cancelled_by_student', 'cancelled_by_teacher'];
class SingleSessionDetails extends Component {
  state = {
    page_error: false,
    loading: true,
    student_session_logs_open: false,
    teacher_session_logs_open: false,
    zoom_session_logs_open: false,
    webhook_logs_open: false,
    charge_loading: false,
    show_modal: false,
    modal_error: false,
    roadmap_loading: false,
    session_time_loading: false,
    change_teacher_id: '',
    change_teacher: false,
    teachers_list: [],
    teachers_loaded: false,
    change_reason: '',
    ignore_rating: false,
    ignore_rating_reason: '',
    rating_error: false,
    replacement_logs: [],
    replacement_logs_open: false,
    email_data: [],
    email_logs_open: false,
    update_session_time: false,
    new_session_time: null,
    session_details: {},
    change_time_loading: false,
    cancelled_by_student: false,
    cancelled_by_teacher: false,
    update_session_duration: false,
    new_duration: null,
    party_group_open: false,
    party_participants: 1,
    by_teacher_student: 1, // 1 for teacher 0 for student ,
    party_confirm: false,
    show_raw_data: false,
    raw_data_search: '',
    from_page: '',
    replacement_requests: [],
    zoom_recording_logs_open: false,
    zoom_recording_details: [],
    penalty_open: false,
    waive_penalty: false,
    waive_penalty_reason: '',
    show_survey_form: false,
    teacher_uuid: '',
    student_zoom_link: '',
    teacher_zoom_link: '',
    care_zoom_link: '',
    open_zoom_link_modal: false,
    is_student: false,
    is_teacher: false,
    is_care: false,
    copy_link: '',
    session_id: '',
    session_end_time: '',
    cancelled_by_care_member: '',
    rescheduled_by_care_member: '',
    view: 'speaker',
    update_qa_score: false,
    qa_score: 0
    // session_change_time_history: []
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    if (uuid) {
      this.setState({ session_uuid: uuid }, () => {
        this.load_data();
        // this.load_change_time_history()
      });
    } else {
      this.setState({ page_error: true });
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('session_details_report');
      const payload = { session_uuid: this.state.session_uuid };
      post_api(url, payload, true)
        .then((result) => {
          const session_details = result.data;
          this.setState(
            {
              session_details,
              ignore_rating: session_details.ignore_student_rating == 1,
              loading: false,
              status: result.data.session_status,
              teacher_penalty_reason: result.data.teacher_penalty_reason,
              teacher_penalty: result.data.teacher_penalty,
              ignore_rating_reason: session_details.ignore_student_rating_reason,
              cancelled_by_care_member: session_details.admin_name,
              rescheduled_by_care_member: session_details.rescheduled_by_care_member
            },
            () => this.get_teachers_list()
          );
          this.load_logs();
          this.load_emails();

          if (session_details.session_type === 'VIDEO_CONSULT') {
            get_api(
              get_api_url(`/v2/teacher_consult/care/sessions/`, false, true) +
                this.state.session_uuid,
              true
            ).then((result) => {
              this.setState({
                consult: result.data
              });
            });
          }
          this.setState({
            teacher_uuid: session_details.teacher_uuid,
            student_uuid: session_details.student_uuid,
            session_id: session_details.raw_data.id,
            session_end_time: session_details?.raw_data?.end_time,
            qa_score: session_details?.qa_score
          });
        })
        .catch(() => this.setState({ loading: false, page_error: true }));
      this.load_zoom_recording_logs();
    });

    get_api(
      get_api_url('/v2/teacher_sessions/care/replacement_requests/sessions/', false, true) +
        this.state.session_uuid,
      true
    ).then((result) => {
      this.setState({
        replacement_requests: result.data.replacement_requests
      });
    });
  };
  // load_change_time_history = () => {
  // 	this.setState({loading: true}, () => {
  // 		const url = get_api_url('get_session_time_change_history');
  // 		const payload = {session_uuid:  this.state.session_uuid}
  // 		post_api(url, payload, true)
  // 		.then((res) => {
  // 			this.setState({
  // 				session_change_time_history: res.data.session_change_time_history,
  // 				loading: false
  // 			})
  // 		})
  // 		.catch((err) => {
  // 			console.log('err: ', err);
  // 			this.setState({loading: false})

  // 		})
  // 	})
  // }
  load_zoom_recording_logs = () => {
    const zoom_url = get_api_url('/v2/zoom_services/get_recording_logs', false, true);
    const payload = { session_uuid: this.state.session_uuid };
    post_api(zoom_url, payload, true)
      .then((response) => {
        this.setState({
          zoom_recording_details: [...response.data.state_records_for_recording_instance]
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  load_emails = () => {
    // const { session_uuid } = this.state;
    // const payload = { session_uuid };
    // const url = get_api_url('get_email_logs_session', true);
    // post_api(url, payload, true)
    // 	.then((res) => {
    // 		this.setState({ email_data: [...res.data.email_data] });
    // 	})
    // 	.catch((e) => console.log(e));
  };

  load_logs = () => {
    const { session_uuid } = this.state;
    const payload = { session_uuid };
    const url = get_api_url('get_request_replacement_logs');
    post_api(url, payload, true)
      .then((res) => {
        this.setState({ replacement_logs: [...res.data.session_logs] });
      })
      .catch((e) => console.log(e));
  };

  get_teachers_list = () => {
    this.setState({ teachers_loaded: false, loading: true }, () => {
      const url = get_api_url('teachers_list_for_new_session');
      const { session_details } = this.state;
      const data = {
        student_uuid: session_details.student_uuid,
        session_type: session_details.session_type
      };
      post_api(url, data, true)
        .then((response) => {
          const { teachers } = response.data;
          teachers.sort((a, b) => a.name.localeCompare(b.name));
          this.setState({ teachers_list: teachers, teachers_loaded: true, loading: false });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  charge_transaction = () => {
    this.setState({ charge_loading: true }, () => {
      const url = get_api_url('charge_customer');
      const payload = {
        session_uuid: this.state.session_uuid,
        student_uuid: this.state.session_details.student_uuid
      };
      post_api(url, payload, true)
        .then((response) => {
          this.load_data();
          this.setState({ charge_loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ charge_loading: false });
        });
    });
  };

  go_back = () => {
    this.props.history.goBack();
  };

  toggle_student_session_logs = () =>
    this.setState({ student_session_logs_open: !this.state.student_session_logs_open });

  // toggle_teacher_session_logs = () =>
  // 	this.setState({ teacher_session_logs_open: !this.state.teacher_session_logs_open });
  toggle_zoom_session_logs = () =>
    this.setState({ zoom_session_logs_open: !this.state.zoom_session_logs_open });

  toggle_zoom_webhook_logs = () =>
    this.setState({ webhook_logs_open: !this.state.webhook_logs_open });

  toggle_consult = () => this.setState({ consult_open: !this.state.consult_open });

  toggle_zoom_recording_logs = () =>
    this.setState({ zoom_recording_logs_open: !this.state.zoom_recording_logs_open });

  render_student_session_logs = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_student_session_logs}>
        MYT Logs:{' '}
        {this.state.session_details.student_session_logs?.length +
          this.state.session_details.teacher_session_logs?.length}
        <img
          className={this.state.student_session_logs_open ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
      </div>
      {this.state.student_session_logs_open ? (
        <div>
          <table>
            <tbody>
              <tr>
                <td>User</td>
                <td>Start Time</td>
                <td>End Time</td>
                <td>Is Admin</td>
              </tr>
              {this.state.session_details.student_session_logs.map((log) => (
                <tr key={log.id}>
                  <td>Student</td>
                  <td>
                    {moment(log.start_time)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm A z')}
                  </td>
                  <td>
                    {moment(log.end_time)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm A z')}
                  </td>
                  <td>{log.is_admin === 1 ? 'True' : 'False'}</td>
                </tr>
              ))}
              {this.state.session_details.teacher_session_logs.map((log) => (
                <tr key={log.id}>
                  <td>Teacher</td>
                  <td>
                    {moment(log.start_time)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm A z')}
                  </td>
                  <td>
                    {moment(log.end_time)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm A z')}
                  </td>
                  <td>{log.is_admin === 1 ? 'True' : 'False'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );

  toggle_email_logs = () => this.setState({ email_logs_open: !this.state.email_logs_open });

  email_logs = () => {
    const { email_data, email_logs_open } = this.state;
    if (email_data?.length > 0) {
      return (
        <div>
          <div className="details-session-logs-header" onClick={this.toggle_email_logs}>
            Emails: {email_data?.length}
            <img
              className={email_logs_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          </div>
          {email_logs_open ? (
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div className="ct-col">Type</div>
                <div className="ct-col">To</div>
                <div className="ct-col">Delivered</div>
                <div className="ct-col">Opened</div>
                <div className="ct-col">Primary action</div>
              </div>
              {email_data.map((email) => (
                <div className="ct-row" key={email.id}>
                  <div className="ct-col ct-bw ct-sm-font">{email.email_type}</div>
                  <div className="ct-col ct-bw ct-sm-font">{email.to_address}</div>
                  {/* <div className="ct-col ct-xsi ct-hf">{email.is_delivered ? 
									<img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" /> 
									: <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />}</div>
									<div className="ct-col ct-xsi ct-hf">{email.is_opened ?
										<img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
										: null}</div>
									<div className="ct-col ct-xsi ct-hf">{email.primary_action_count > 0 ?
										<img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
										: null}</div> */}
                  <div className="ct-col ct-sm-font ct-bw">
                    {email.is_delivered === 1
                      ? moment(email.delivery_time)
                          .tz(this.props.iana_timezone)
                          .format('YYYY-MM-DD hh:mm A z')
                      : '--'}
                  </div>
                  <div className="ct-col ct-sm-font ct-bw">
                    {email.is_opened === 1
                      ? moment(email.last_opened_time)
                          .tz(this.props.iana_timezone)
                          .format('YYYY-MM-DD hh:mm A z')
                      : '--'}
                  </div>
                  <div className="ct-col ct-sm-font ct-bw">
                    {email.primary_action_count > 0
                      ? moment(email.primary_action_last_opened_time)
                          .tz(this.props.iana_timezone)
                          .format('YYYY-MM-DD hh:mm A z')
                      : '--'}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  toggle_replacement_webhook_logs = () =>
    this.setState({ replacement_logs_open: !this.state.replacement_logs_open });

  render_request_replacement_logs = () => {
    const { replacement_logs, replacement_logs_open } = this.state;
    if (replacement_logs?.length > 0) {
      return (
        <div>
          <div
            className="details-session-logs-header"
            onClick={this.toggle_replacement_webhook_logs}
          >
            Request Replacement Logs: {replacement_logs?.length}
            <img
              className={replacement_logs_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          </div>
          {replacement_logs_open ? (
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div className="ct-col">Action</div>
                <div className="ct-col">By</div>
                <div className="ct-col">Date</div>
              </div>
              {replacement_logs.map((log, index) => {
                const original_link = `/teacherprofile?id=${log.original_teacher_uuid}`;
                const new_link = `/teacherprofile?id=${log.new_teacher_uuid}`;
                return (
                  <div className="ct-row" key={index + log.uuid}>
                    <div className="ct-col">
                      {log.action === 'WAITING' ? 'Replacement Created' : log.action}
                    </div>
                    <div className="ct-col">
                      {log.action === 'Replacement Created' ? (
                        <a className="link-no-dec" href={original_link}>
                          {log.original_teacher_name}
                        </a>
                      ) : (
                        <a className="link-no-dec" href={new_link}>
                          {log.new_teacher_name}
                        </a>
                      )}
                    </div>
                    <div className="ct-col">
                      {log.action === 'Replacement Created'
                        ? moment(log.waiting_start_time)
                            .tz(this.props.iana_timezone)
                            .format('MMM DD hh:mm A z')
                        : moment(log.modified_date)
                            .tz(this.props.iana_timezone)
                            .format('MMM DD hh:mm A z')}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  session_status_updates = () => {
    const status_data = this.state?.session_details?.session_status_obj;
    const status = status_data?.status;
    const sub_status = status_data?.sub_status;
    return (
      <>
        Status:
        <table>
          <tbody>
            <tr>
              <td>Status</td>
              <td>Sub Status</td>
            </tr>
            <tr>
              <td>{status}</td>
              <td>
                {sub_status ? (
                  <ol>
                    {sub_status.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ol>
                ) : (
                  '--'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  render_zoom_hook_logs = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_zoom_webhook_logs}>
        Zoom Webhook Logs (Raw): {this.state.session_details.zoom_webhook?.length}
        <img
          className={this.state.webhook_logs_open ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
      </div>
      {this.state.webhook_logs_open ? (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Event</div>
            <div className="ct-col">Start Time</div>
            <div className="ct-col">Name</div>
            <div className="ct-col ct-hf">Join Time</div>
            <div className="ct-col ct-hf">Leave Time</div>
            <div className="ct-col ct-hf ">Playback</div>
            <div className="ct-col ct-hf ">Password</div>
          </div>
          {this.state.session_details.zoom_webhook.map((log, index) => {
            const details = JSON.parse(log.body);
            let password = '';
            try {
              password = details.payload.object.password;
            } catch (e) {}
            return (
              <div className="ct-row" key={index + details.payload.object.id}>
                <div className="ct-col ct-sm-font ct-bw">{details.event}</div>
                <div className="ct-col ct-sm-font ct-bw">
                  {moment(log.created_date)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')}
                </div>
                <div className="ct-col">
                  {details.payload.object.participant
                    ? details.payload.object.participant.user_name
                    : '-'}
                </div>
                <div className="ct-col ct-hf">
                  {!!details.payload.object.participant &&
                  !!details.payload.object.participant.join_time
                    ? moment(details.payload.object.participant.join_time)
                        .tz(this.props.iana_timezone)
                        .format('hh:mm A z') || '-'
                    : '-'}
                </div>
                <div className="ct-col ct-hf">
                  {!!details.payload.object.participant &&
                  details.payload.object.participant.leave_time
                    ? moment(details.payload.object.participant.leave_time)
                        .tz(this.props.iana_timezone)
                        .format('hh:mm A z') || '-'
                    : '-'}
                </div>
                <div className="ct-col ct-hf">
                  {details.event === 'recording.completed' ? (
                    <a
                      href={details.payload.object.share_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Play
                    </a>
                  ) : (
                    '-'
                  )}
                </div>
                <div className="ct-col ct-hf">{password || '-'}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );

  render_zoom_recording_logs = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_zoom_recording_logs}>
        Zoom Recording Playback Logs: {this.state.zoom_recording_details?.length}
        <img
          className={this.state.zoom_recording_logs_open ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
      </div>
      {this.state.zoom_recording_logs_open ? (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-qf">ID</div>
            <div className="ct-col">Playback Start Time</div>
            <div className="ct-col">Playback End Time</div>
            <div className="ct-col">Playback Duration (in min)</div>
          </div>
          {this.state.zoom_recording_details.map((record, id) => (
            <div className="ct-row" key={id}>
              <div className="ct-col ct-sm-font ct-qf">{id + 1}</div>
              <div className="ct-col ct-sm-font">
                <div>
                  {moment(record.recording_start_time)
                    .tz(this.props.iana_timezone)
                    .format(day_format)}
                </div>
                <div>
                  {moment(record.recording_start_time)
                    .tz(this.props.iana_timezone)
                    .format(time_format)}
                </div>
              </div>
              <div className="ct-col ct-sm-font">
                <div>
                  {moment(record.recording_end_time)
                    .tz(this.props.iana_timezone)
                    .format(day_format)}
                </div>
                <div>
                  {moment(record.recording_end_time)
                    .tz(this.props.iana_timezone)
                    .format(time_format)}
                </div>
              </div>
              <div className="ct-col ct-sm-font">{record.recording_playtime}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );

  processRecordings = () => {
    this.setState({
      loading: true
    });
    post_api(
      get_api_url(
        `/v2/teacher_consult/care/sessions/${this.state.session_uuid}/recordings/process`,
        false,
        true
      ),
      null,
      true
    ).then((res) => {
      if (this.state.session_details.session_type === 'VIDEO_CONSULT') {
        get_api(
          get_api_url(`/v2/teacher_consult/care/sessions/`, false, true) + this.state.session_uuid,
          true
        ).then((result) => {
          this.setState({
            consult: result.data,
            loading: false
          });
        });
      }
    });
  };

  renderConsultDetails = () => {
    if (!this.state.consult) {
      return null;
    }
    const { consult } = this.state;
    return (
      <div>
        <div className="details-session-logs-header" onClick={this.toggle_consult}>
          Consult Status: {consult.status} | Duration:{' '}
          {`${Math.floor(consult.duration / 60)} min ${consult.duration % 60} sec`} | Roadmap
          Created: {consult.roadmap_created}
          <img
            className={this.state.webhook_logs_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>

        {this.state.consult_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Call Start Time</div>
              <div className="ct-col">Duration</div>
              <div className="ct-col">Status</div>
            </div>
            {consult.calls.map((call, index) => (
              <div className="ct-row" key={index}>
                <div className="ct-col">{call.call_start_time}</div>
                <div className="ct-col">
                  {`${Math.floor(call.duration / 60)} min ${call.duration % 60} sec`} seconds
                </div>
                <div className="ct-col">{call.status}</div>
              </div>
            ))}
          </div>
        ) : null}
        {consult.recording_url ? (
          <div>
            <h2>Consult Recording:</h2>
            <div>
              <video controls>
                <source src={consult.recording_url} type="audio/mpeg" />
              </video>
            </div>
          </div>
        ) : null}
        {this.state.session_details.session_type === 'VIDEO_CONSULT' ? (
          <button onClick={this.processRecordings}>Re-process Recordings</button>
        ) : null}
      </div>
    );
  };

  render_zoom_session_logs = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_zoom_session_logs}>
        Zoom Webhook Logs (Processed): {this.state.session_details.zoom_session_logs?.length}
        <img
          className={this.state.zoom_session_logs_open ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
      </div>
      {this.state.zoom_session_logs_open ? (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">User</div>
            <div className="ct-col">Joined Time</div>
            <div className="ct-col">Leaving Time</div>
          </div>
          {this.state.session_details.zoom_session_logs.map((log) => (
            <div className="ct-row" key={log.id}>
              <div className="ct-col">{log.student_uuid ? 'Student' : 'Teacher'}</div>
              <div className="ct-col">
                {moment(log.joined_time)
                  .tz(this.props.iana_timezone)
                  .format('YYYY-MM-DD hh:mm A z')}
              </div>
              <div className="ct-col">
                {moment(log.leaving_time)
                  .tz(this.props.iana_timezone)
                  .format('YYYY-MM-DD hh:mm A z')}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );

  create_roadmap = () => {
    const details = this.state.session_details;
    this.setState({ roadmap_loading: true }, () => {
      const url = get_api_url('create_roadmap');
      const payload = {
        session_uuid: details.session_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ roadmap_loading: false }, () => {
            this.props.set_notification_variable(true, 'success', 'Roadmap successfully created');
            this.load_data();
          });
        })
        .catch(() => {
          this.setState({ roadmap_loading: false }, () => {
            this.props.set_notification_variable(
              true,
              'error',
              'Error occured while creating roadmap'
            );
          });
        });
    });
  };

  update_session_30_minutes = () => {
    this.setState({ session_time_loading: true }, () => {
      const url = get_api_url('update_session_30_minutes');
      const payload = {
        session_uuid: this.state.session_details.session_uuid,
        student_uuid: this.state.session_details.student_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ session_time_loading: false });
          this.load_data();
          this.props.set_notification_variable(
            true,
            'success',
            'Session time succesfully changed to 30 min'
          );
        })
        .catch((e) => {
          console.log(e);
          this.setState({ session_time_loading: false });
          this.props.set_notification_variable(
            true,
            'error',
            'Some error occured. Please contact dev team'
          );
        });
    });
  };

  render_group_session_details = () => {
    const details = this.state.session_details;
    if (details.session_type === 'GROUP_SESSION') {
      return (
        <>
          <h3>Group session properties: </h3>
          <table>
            <tbody />
          </table>
        </>
      );
    }
    return null;
  };

  render_request_replacement = () => (
    <>
      <div
        className="details-session-logs-header"
        onClick={() => {
          this.setState({
            showReplacements: !this.state.showReplacements
          });
        }}
      >
        Replacements {this.state.replacement_requests?.length}
        <img
          className={this.state.showReplacements ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
      </div>
      {this.state.showReplacements ? (
        <div>
          <table>
            <thead>
              <tr>
                <td>Requested By</td>
                <td>Replacement Request Status</td>
                <td>Replacement Request Action</td>
                <td>Reason of Replacement</td>
                <td>Teacher Accepted</td>
              </tr>
            </thead>
            <tbody>
              {this.state.replacement_requests.map((rr) => {
                const teacher_url = `/teacherprofile?id=${rr.teacher_uuid}`;
                const new_teacher_url = `/teacherprofile?id=${rr.new_teacher_uuid}`;
                return (
                  <tr key={rr.id}>
                    <td>
                      <a className="link-no-dec" href={teacher_url}>
                        <div className="profile-img-name">
                          <img
                            src={rr.teacher_profile_photo}
                            alt="i"
                            onError={image_error_replace}
                          />
                          {rr.teacher_name}
                        </div>
                      </a>
                    </td>
                    <td>{rr.replacement_request_status}</td>
                    <td>{rr.replacement_request_action}</td>
                    <td>{rr.reason_for_replacement}</td>
                    <td>
                      <a className="link-no-dec" href={new_teacher_url}>
                        <div className="profile-img-name">
                          <img
                            src={rr.new_teacher_profile_photo}
                            alt="i"
                            onError={image_error_replace}
                          />
                          {rr.new_teacher_name}
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );

  // change_date_time =  (date) => {
  // 	const originalTime = moment(date).tz(this.props.iana_timezone);
  // 	const formattedDate = originalTime.format('DD-MM-YY HH:mm');
  // 	return formattedDate
  // }

  // render_change_time_history = () => {
  // 	return (
  // 		<>
  // 			<div
  // 				className="details-session-logs-header"
  // 				onClick={() => {
  // 					this.setState({
  // 						change_time_histoey: !this.state.change_time_histoey,
  // 					});
  // 				}}
  // 			>
  // 				Change Time History
  // 				<img
  // 					className={this.state.showReplacements ? 'reverse-expand' : 'normal-expand'}
  // 					src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
  // 					alt="exp"
  // 				/>
  // 			</div>
  // 			{this.state.change_time_histoey ? (
  // 				<>
  // 					<div>
  // 						<table>
  // 							<thead>
  // 								<tr>
  // 									<td>Original Time</td>
  // 									<td>Current Time</td>
  // 									<td>Requester UUID</td>
  // 									<td>Requester Type</td>
  // 									<td>Requested By</td>
  // 								</tr>
  // 							</thead>
  // 							<tbody>
  // 								{this.state.session_change_time_history?.map((item, index) => {

  // 									return (
  // 										<tr key={index}>
  // 										<td>{this.change_date_time(item.original_session_time)}</td>
  // 										<td>{this.change_date_time(item.current_session_time)}</td>
  // 										<td>{item.requester_uuid}</td>
  // 										<td>{item.requester_type}</td>
  // 										<td>{item.reschedule_requested_by}</td>
  // 									</tr>
  // 									)
  // 								})}
  // 							</tbody>
  // 						</table>
  // 					</div>
  // 				</>
  // 			) : null}
  // 		</>
  // 	);
  // }

  render_cancellation = () => {
    const details = this.state.session_details;
    const iana = this.props.iana_timezone;
    if (cancelled_status.indexOf(details.session_status) !== -1) {
      return (
        <>
          <h3>Cancelled: </h3>
          <table>
            <tbody>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">By student: </td>
                <td>{check_bool_flag(details.cancelled_by_student)}</td>
                <td className="teacher-table-detail">By teacher: </td>
                <td>{check_bool_flag(details.cancelled_by_teacher)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">By admin: </td>
                <td>{check_bool_flag(details.cancelled_by_admin)}</td>
                <td className="teacher-table-detail">Time: </td>
                <td>
                  {details.cancellation_time
                    ? moment_format_tz(details.cancellation_time, iana)
                    : '--'}
                </td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Admin Name: </td>
                <td>{check_bool_flag(details.cancelled_by_admin)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Charge cancellation: </td>
                <td>{check_bool_flag(details.charge_cancellation)}</td>
                <td className="teacher-table-detail">Reason: </td>
                <td>{check_null_text(details.reason_for_cancel_reschedule)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Detailed reason: </td>
                <td>{check_null_text(details.detailed_reason_for_cancel_reschedule)}</td>
                <td />
                <td />
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Teacher penalty: </td>
                <td>{check_null_text(details.teacher_penalty)}</td>
                <td className="teacher-table-detail">Penalty reason: </td>
                <td>{check_null_text(details.teacher_penalty_reason)}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  render_feedback = () => {
    const details = this.state.session_details;
    const iana = this.props.iana_timezone;
    if (details.session_status === 'FINISHED') {
      return (
        <>
          <h3>Feedback: </h3>
          <table>
            <tbody>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student rating: </td>
                <td>{details.student_rating}</td>
                <td className="teacher-table-detail">Teacher rating: </td>
                <td>{details.teacher_rating}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student review: </td>
                <td>{check_null_text(details.student_review)}</td>
                <td className="teacher-table-detail">Teacher review: </td>
                <td>{check_null_text(details.teacher_review)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student review reason: </td>
                <td>{check_null_text(details.student_rating_reason)}</td>
                <td className="teacher-table-detail">Teacher review reason: </td>
                <td>{check_null_text(details.teacher_rating_reason)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Ignore student rating: </td>
                <td>{check_bool_flag(details.ignore_student_rating)}</td>
                <td className="teacher-table-detail">Ignore student rating reason: </td>
                <td>{check_null_text(details.ignore_student_rating_reason)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Ignore student rating time: </td>
                <td>{moment_format_tz(details.ignore_student_rating_update_time, iana)}</td>
                <td className="teacher-table-detail">Teacher what went wrong: </td>
                <td>{check_null_text(details.teacher_what_went_wrong)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Reacap: </td>
                <td>{check_null_text(details.recap)}</td>
                <td className="teacher-table-detail">Notes: </td>
                <td>{check_null_text(details.notes)}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  render_qa_score = () => {
    const details = this.state.session_details;
    const iana = this.props.iana_timezone;
    if (details.session_status === 'FINISHED') {
      return (
        <>
          <h3>QA Score Details: </h3>
          <table>
            <tbody>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Qa Score: </td>
                <td>{details.qa_score}</td>
                <td className="teacher-table-detail">QA Score Updated By: </td>
                <td>
                  {details.qa_score_updated_by_name ? details.qa_score_updated_by_name : '--'}
                </td>
                <td>
                  <Button onClick={this.open_qa_score}>Edit QA Score</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  render_join_details = () => {
    const details = this.state.session_details;
    if (
      Object.keys(details)?.length > 0 &&
      (details.session_status === 'ACTIVE' || details.session_status === 'FINISHED')
    ) {
      const student_logs = details.student_session_logs[0];
      const teacher_logs = details.teacher_session_logs[0];
      let student_join_time = '--';
      let teacher_join_time = '--';
      const custom_style = {};
      let student_device = {};
      let teacher_device = {};
      let student_user_agent = '';
      let teacher_user_agent = '';
      try {
        student_join_time = moment(student_logs.start_time)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD hh:mm A z');
      } catch (e) {}
      try {
        if (student_logs.user_agent) {
          student_user_agent = student_logs.user_agent;
          student_device = UaParser(student_user_agent);
        }
      } catch (e) {}
      try {
        teacher_join_time = moment(teacher_logs.start_time)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD hh:mm:ss A z');
        if (moment(teacher_logs.start_time).diff(moment(details.epoch_start_time), 's') > 0) {
          custom_style.color = 'ed4d4d';
        }
      } catch (e) {}
      try {
        if (teacher_logs.user_agent) {
          teacher_user_agent = teacher_logs.user_agent;
          teacher_device = UaParser(teacher_user_agent);
        }
      } catch (e) {}
      return (
        <>
          <h3>Joined: </h3>
          <table>
            <tbody>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student joined :</td>
                <td>{details.student_joined === 1 ? 'True' : 'False'}</td>
                <td className="teacher-table-detail">Teacher joined :</td>
                <td>{details.teacher_joined === 1 ? 'True' : 'False'}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student join time :</td>
                <td>{student_join_time}</td>
                <td className="teacher-table-detail">Teacher join time :</td>
                <td>
                  <span style={custom_style}>{teacher_join_time}</span>
                </td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student OS:</td>
                <td>{student_device.os ? student_device.os.name : '--'}</td>
                <td className="teacher-table-detail">Teacher OS:</td>
                <td>{teacher_device.os ? teacher_device.os.name : '--'}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student Browser:</td>
                <td>{student_device.browser ? student_device.browser.name : '--'}</td>
                <td className="teacher-table-detail">Teacher Browser:</td>
                <td>{teacher_device.browser ? teacher_device.browser.name : '--'}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Student user agent :</td>
                <td>{check_null_text(student_user_agent)}</td>
                <td className="teacher-table-detail">Teacher user agent :</td>
                <td>{check_null_text(teacher_user_agent)}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  edit_recording = () => {
    const { session_details, session_uuid } = this.state;
    const replay_url = session_details.session_replay;
    this.props.history.push({
      pathname: '/croprecording',
      state: {
        replay_url,
        session_uuid
      }
    });
  };

  handleViewChange = (view) => {
    this.setState({ view });
  };

  render_replays = () => {
    const { view } = this.state;
    const details = this.state.session_details;
    const hasSpeakerView = !!details.session_replay;
    const hasGalleryView = !!details.gallery_view;
    if (!hasSpeakerView && hasGalleryView && view === 'speaker') {
      this.setState({ view: 'gallery' });
    }
    if ((hasSpeakerView || hasGalleryView) && details.session_type !== 'YOGA') {
      return (
        <>
          <div className="flex items-center gap-10 mb-10 mt-6">
            {hasSpeakerView && (
              <div
                onClick={() => this.handleViewChange('speaker')}
                className={` cursor-pointer font-bold text-xl  ${view === 'speaker' ? 'border-b-2 border-black underline-offset-4' : ''} `}
              >
                Speaker view
              </div>
            )}
            {hasGalleryView && (
              <div
                onClick={() => this.handleViewChange('gallery')}
                className={` cursor-pointer font-bold text-xl ${view === 'gallery' ? 'border-b-2 border-black underline-offset-4' : ''}`}
              >
                Gallery view
              </div>
            )}
          </div>
          {view === 'speaker' && hasSpeakerView && (
            <CustomPlayer video_url={details.session_replay} />
          )}
          {view === 'gallery' && hasGalleryView && (
            <CustomPlayer video_url={details.gallery_view} />
          )}
        </>
      );
    }
    if (details.session_type === 'YOGA' && details.session_replay !== '') {
      return (
        <>
          <div className="flex items-center gap-10 mb-10 mt-6">
            <div className="cursor-pointer font-bold text-xl border-b-2 border-black underline-offset-4">
              Session Replays
            </div>
          </div>
          <CustomPlayer video_url={details.session_replay} />
        </>
      );
    }
    return null;
  };

  handle_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submit_penalty = () => {
    this.setState({ charge_loading: true }, () => {
      const { waive_penalty_reason, waive_penalty } = this.state;
      const url = get_api_url('update_session_penalty');
      const payload = {
        session_uuid: this.state.session_uuid,
        waive_penalty: waive_penalty ? 1 : 0,
        waive_penalty_reason: waive_penalty_reason || ''
      };
      post_api(url, payload, true)
        .then(() => {
          this.close_penalty();
          this.load_data();
          this.setState({ charge_loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ charge_loading: false });
        });
    });
  };

  render_penalty_popup = () => {
    const details = this.state.session_details;
    return (
      <div>
        <h4>Change Popup Details:</h4>
        <div style={{ marginBottom: '20px' }}>
          <CustomCheckBox
            label="Waive Penalty"
            checked={this.state.waive_penalty}
            onClick={() => this.setState({ waive_penalty: !this.state.waive_penalty })}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <textarea
            value={this.state.waive_penalty_reason}
            name="waive_penalty_reason"
            onChange={this.handle_change}
            style={{ width: '300px', height: '100px' }}
            maxLength="200"
            placeholder="Enter waive penalty reason (max 200 char)"
          />
        </div>
        <button onClick={this.submit_penalty}>Submit</button>
      </div>
    );
  };

  close_penalty = () =>
    this.setState({ penalty_open: false, waive_penalty: false, waive_penalty_reason: '' });

  open_penalty = () => {
    const details = this.state.session_details;
    this.setState({
      penalty_open: true,
      waive_penalty: details.waive_penalty,
      waive_penalty_reason: details.waive_penalty_reason
    });
  };

  render_penalty = () => {
    const details = this.state.session_details;
    return (
      <>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Waive Penalty :</td>
              <td>{details.waive_penalty === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Waive Penalty Reason:</td>
              <td>{details.waive_penalty_reason}</td>
            </tr>
          </tbody>
        </table>
        {!isViewOnly(this.props.access_type) ? (
          <button onClick={this.open_penalty}>Change Penalty</button>
        ) : null}
      </>
    );
  };

  open_update_duration = () =>
    this.setState({
      update_session_duration: true,
      new_duration: this.state.session_details.duration
    });

  open_qa_score = () => this.setState({ update_qa_score: true });

  toggle_party_open = () =>
    this.setState({ party_group_open: !this.state.party_group_open, party_confirm: false });

  render_session_details = () => {
    const details = this.state.session_details;
    const teacher_url = `/teacherprofile?id=${details.teacher_uuid}`;
    const student_url = `/studentprofile?id=${details.student_uuid}`;
    let student_link = '';
    let teacher_link = '';
    let care_link = '';
    let livekit_care_link = '';
    let livekit_dashboard = '';

    if (details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') {
      let zoom_link = details.student_join_link;
      student_link = details.student_join_link;
      teacher_link = details.teacher_join_link;
      care_link = details.care_join_link;
      if (zoom_link.indexOf('?') === -1) {
        zoom_link += '?';
      } else {
        zoom_link += '&';
      }
      // student_link = `${zoom_link}name=${details.student_name}`;
      // teacher_link = `${zoom_link}name=${details.teacher_name} test`
      const teacher_name = btoa('New prajval');
      // teacher_link = `${teacher_join_link}name=${details.teacher_name}`;
      // let care_name = new Buffer('Myt-Care').toString('base64');
      const care_name = btoa('Myt-Care');

      // care_link = `${care_join_link}prefer=1&un=${care_name}`;
      // commenting as part of HRIDAYA-454 as zak is already used
      // if (!!details.student_zak_token) {
      // 	student_link = `${student_link}&zak=${details.student_zak_token}`;
      // }
      // if (!!details.teacher_zak_token) {
      // 	teacher_link = `${teacher_link}&zak=${details.teacher_join_link}`;
      // 	care_link = `${care_link}&zak=${details.care_join_link}`;
      // }

      const isProduction = window.location.hostname === 'hridaya.myyogateacher.com';
      const host = isProduction
        ? 'https://teacher.myyogateacher.com'
        : 'http://teacher-testenv1.myyogateacher.com';

      livekit_dashboard = isProduction
        ? 'https://cloud.livekit.io/projects/p_2nxn09d2ot6/sessions'
        : 'https://cloud.livekit.io/projects/p_1q9m81ky6p4/sessions';

      livekit_care_link = `${host}/sessions/livekit?session_uuid=${details.session_uuid}&token=${getCookie('cms_token_3')}`;
    }

    const handleOpenLiveKitCare = () => {
      window.open(livekit_care_link, '_blank', 'noopener,noreferrer');
    };

    const scheduled_by_str = details.session_type === 'GROUP_SESSION' ? 'care' : 'student';
    return (
      <div>
        <h3>About session:</h3>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Teacher Name:</td>
              <td>
                <a className="link-no-dec" href={teacher_url}>
                  {details.teacher_name}
                </a>
              </td>
              <td className="teacher-table-detail">Student Name :</td>
              <td>
                <a className="link-no-dec" href={student_url}>
                  {details.student_name}
                </a>
              </td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session uuid :</td>
              <td>{details.session_uuid}</td>
              {/* <td className="teacher-table-detail">Meeting number :</td>
							<td>{details.zoom_meeting_id}</td> */}
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Status:</td>
              <td>{details.session_status}</td>
              <td className="teacher-table-detail">Type:</td>
              <td>{details.session_type}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Start time: </td>
              <td>
                {moment(details.epoch_start_time)
                  .tz(this.props.iana_timezone)
                  .format('YYYY-MM-DD hh:mm A z')}
              </td>
              <td className="teacher-table-detail">Duration</td>
              <td>{details.duration}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Free: </td>
              <td>{details.is_trial === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">First free: </td>
              <td>{details.is_first_free_session === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Paid: </td>
              <td>{details.is_trial === 1 ? 'False' : 'True'}</td>
              <td className="teacher-table-detail">First paid: </td>
              <td>{details.is_first_paid_session === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Repeat: </td>
              <td>{details.is_recurring === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Original repeat session: </td>
              <td>
                {details.repeat_session_uuid ? (
                  <a href={`/sessiondetails?id=${details.repeat_session_uuid}`}>Click Here</a>
                ) : (
                  '--'
                )}
              </td>
            </tr>
            {this.state.rescheduled_by_care_member != '' ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Rescheduled by Care Member: </td>
                <td>{this.state.rescheduled_by_care_member}</td>
              </tr>
            ) : null}
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Rescheduled by teacher: </td>
              <td>{details.rescheduled_by_teacher === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Rescheduled by: </td>
              <td>{details.rescheduled_by}</td>
            </tr>
            {this.state.cancelled_by_care_member != '' && details.session_status == 'CANCELLED' ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Cancelled by Care Member: </td>
                <td>{details.admin_name}</td>
              </tr>
            ) : null}
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Scheduled by {scheduled_by_str}: </td>
              <td>{details.scheduled_by_teacher === 1 ? 'False' : 'True'}</td>
              <td className="teacher-table-detail">Scheduled by teacher: </td>
              <td>{details.scheduled_by_teacher === 1 ? 'True' : 'False'}</td>
            </tr>
            {details.session_type === 'YOGA' ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Scheduled from web: </td>
                <td>{details.scheduled_from_web === 1 ? 'True' : 'False'}</td>
                <td className="teacher-table-detail">Scheduled from mobile: </td>
                <td>{details.scheduled_from_web === 1 ? 'False' : 'True'}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {this.render_penalty()}
        {/* {this.render_change_time_history()} */}
        {this.render_request_replacement()}
        {/* session status updates status & substatus */}
        {this.state.session_details.session_status_obj && this.session_status_updates()}
        {/* {this.render_group_session_details()} */}
        {this.render_cancellation()}
        {this.render_join_details()}
        {this.render_replays()}
        {this.render_feedback()}
        {this.render_qa_score()}
        {!isViewOnly(this.props.access_type) ? (
          <>
            <Button onClick={this.toggle_modal}>Edit</Button>
            <Button onClick={this.handle_survey_modal}>Survey Form</Button>
            {details.session_status === 'SCHEDULED' || details.session_status === 'ACTIVE' ? (
              <Button onClick={this.open_update_time}>Update session time</Button>
            ) : null}
            {details.session_status !== 'CANCELLED' ? (
              <Button onClick={this.open_update_duration}>Update session duration</Button>
            ) : null}
            {(details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') &&
            moment(details.epoch_end_time).diff(moment(details.epoch_start_time), 'minutes') >
              30 ? (
              <Button
                disabled={this.state.session_time_loading}
                onClick={this.update_session_30_minutes}
              >
                Update session to 30 min
              </Button>
            ) : null}
            {details.roadmap_present === 0 && details.session_status === 'FINISHED' ? (
              <Button disabled={this.state.roadmap_loading} onClick={this.create_roadmap}>
                Create Roadmap
              </Button>
            ) : null}
            {details.is_trial === 0 &&
            details.session_status === 'FINISHED' &&
            Object.keys(details.transaction)?.length === 0 ? (
              <Button onClick={this.charge_transaction} disabled={this.state.charge_loading}>
                Charge User
              </Button>
            ) : null}
          </>
        ) : null}

        <br />

        {details.call_provider === 'livekit' && (
          <>
            <p>This is a livekit session. For video session logs visit, livekit dashboard</p>
            <br />
          </>
        )}

        {(details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') &&
        details.call_provider === 'livekit' ? (
          <button className="btn-link" onClick={handleOpenLiveKitCare}>
            Join as care
          </button>
        ) : null}

        {(details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') &&
        details.call_provider !== 'livekit' ? (
          <>
            <a
              className="btn-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => this.trigger_zoom_signature('student')}
            >
              Student Link
            </a>
            <a
              className="btn-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => this.trigger_zoom_signature('teacher')}
            >
              Teacher Link
            </a>
            <a
              className="btn-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => this.trigger_zoom_signature('care')}
            >
              Join as care
            </a>
          </>
        ) : null}

        {details.call_provider === 'livekit' && (
          <a
            className="btn-link"
            href={livekit_dashboard}
            target="_blank"
            rel="noopener noreferrer"
          >
            Livekit Dashboard
          </a>
        )}

        {details.call_provider !== 'livekit' && (
          <>
            {/* <a className="btn-link" target="_blank" rel="noopener noreferrer" href={details.join_url}>
							Join url
						</a> */}
            <a
              className="btn-link"
              href="https://zoom.us/account/metrics/livemeetings"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zoom Dashboard
            </a>
          </>
        )}
        {!isViewOnly(this.props.access_type) ? (
          <>
            {details.session_status === 'SCHEDULED' ? (
              <Button
                disabled={!this.state.teachers_loaded}
                onClick={() => this.open_change_teacher(details.session_uuid)}
              >
                Change Teacher
              </Button>
            ) : null}
            {details.session_status === 'FINISHED' && details.session_type === 'YOGA' ? (
              <a
                href={`/session_score_card?session_uuid=${details.session_uuid}`}
                className="link-btn"
                target="_blank"
                rel="noreferrer"
              >
                QA Score Card
              </a>
            ) : null}
            {details.session_type === 'YOGA' ? (
              <Button onClick={this.toggle_party_open}>Change to private group class</Button>
            ) : null}
          </>
        ) : null}
        <button onClick={this.toggle_raw_data}>Raw Data</button>

        {this.email_logs()}
        {this.render_student_session_logs()}
        {/* {this.render_teacher_session_logs()} */}
        {this.render_zoom_session_logs()}
        {this.render_zoom_hook_logs()}
        {this.state.zoom_recording_details?.length > 0 ? this.render_zoom_recording_logs() : null}
        {details.session_type === 'VIDEO_CONSULT' ? this.renderConsultDetails() : null}
      </div>
    );
  };

  render_function = () => {
    if (this.state.page_error) {
      return (
        <div className="common-page-container">
          <div className="profile-go-back">
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
              onClick={this.go_back}
            />
            <span>Oops! something happened. Check your url or try again</span>
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Session Details</div>
        </div>
        {this.render_session_details()}
      </div>
    );
  };

  handle_copy_click = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      this.props.set_notification_variable(true, 'success', 'Linked copied');
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  trigger_zoom_signature = async (client_name) => {
    const { teacher_uuid, student_uuid, session_id, session_end_time } = this.state;
    this.setState({ loading: true });
    const url = get_api_url('/v2/zoom_services/get_zoom_signature', false, true);
    const payload = {
      student_uuid,
      teacher_uuid,
      end_time: session_end_time,
      session_id,
      srole: client_name
    };

    const response = await post_api(url, payload, true);
    if (response.data.status === 'success') {
      this.setState({
        open_zoom_link_modal: true,
        copy_link: response.data.join_url,
        loading: false
      });
    }
  };

  render_zoon_link_modal = () => {
    const { copy_link } = this.state;
    return (
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            this.handle_copy_click(copy_link);
          }}
        >
          Copy Link
        </button>
        <button
          onClick={() => {
            window.open(copy_link, '_blank', 'noopener,noreferrer');
          }}
        >
          Open Link in New Tab
        </button>
      </div>
    );
  };

  close_zoom_link = () => {
    const { open_zoom_link_modal } = this.state;
    this.setState({
      open_zoom_link_modal: !open_zoom_link_modal,
      copy_link: ''
    });
  };

  toggle_modal = () => this.setState({ show_modal: !this.state.show_modal });

  handle_survey_modal = () => this.setState({ show_survey_form: !this.state.show_survey_form });

  close_survey_form = () => this.setState({ show_survey_form: false });

  handle_modal_change = (e) => {
    if (e.target.name === 'status' && e.target.value !== 'CANCELLED') {
      this.setState({
        cancelled_by_student: false,
        cancelled_by_teacher: false
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      modal_error: false,
      rating_error: false
    });
  };

  save_modal = () => {
    const {
      status,
      teacher_penalty,
      teacher_penalty_reason,
      session_uuid,
      ignore_rating,
      ignore_rating_reason,
      cancelled_by_teacher,
      cancelled_by_student
    } = this.state;
    const p = parseInt(teacher_penalty, 10);
    if (p < 0) {
      this.setState({ modal_error: true });
    } else if (ignore_rating && !ignore_rating_reason) {
      this.setState({ rating_error: true });
    } else {
      this.setState({ loading: true, show_modal: false }, () => {
        const url = get_api_url('update_session');
        const payload = {
          session_uuid,
          teacher_penalty: p,
          status,
          teacher_penalty_reason,
          student_uuid: this.state.session_details.student_uuid
        };
        if (status === 'CANCELLED') {
          payload.cancelled_by_admin = 1;
        }
        if (ignore_rating) {
          payload.ignore_rating = 1;
          payload.ignore_rating_reason = ignore_rating_reason.slice(0, 255);
        }
        if (cancelled_by_student) {
          payload.cancelled_by_student = 1;
          payload.cancelled_by_admin = 1;
          payload.cancelled_by_teacher = 0;
        }
        if (cancelled_by_teacher) {
          payload.cancelled_by_teacher = 1;
          payload.cancelled_by_admin = 1;
          payload.cancelled_by_student = 0;
        }
        post_api(url, payload, true)
          .then(() => this.load_data())
          .catch(() => this.setState({ loading: false, page_error: true }));
      });
    }
  };

  toggle_ignore_student = () => this.setState({ ignore_rating: !this.state.ignore_rating });

  toggle_cancel_student = () => {
    let { status } = this.state;
    if (!this.state.cancelled_by_student) {
      status = 'CANCELLED';
    }
    this.setState({
      cancelled_by_student: !this.state.cancelled_by_student,
      status
    });
  };

  toggle_cancel_teacher = () => {
    let { status } = this.state;
    if (!this.state.cancelled_by_student) {
      status = 'CANCELLED';
    }
    this.setState({
      cancelled_by_teacher: !this.state.cancelled_by_teacher,
      status
    });
  };

  toggle_cancel = (option) => {
    let { status } = this.state;
    if (option === 'cancelled_by_student') {
      if (!this.state.cancelled_by_student) {
        status = 'CANCELLED';
      }
      this.setState({
        cancelled_by_student: !this.state.cancelled_by_student,
        cancelled_by_teacher: this.state.cancelled_by_student,
        status
      });
    } else if (option === 'cancelled_by_teacher') {
      if (!this.state.cancelled_by_teacher) {
        status = 'CANCELLED';
      }
      this.setState({
        cancelled_by_teacher: !this.state.cancelled_by_teacher,
        cancelled_by_student: this.state.cancelled_by_teacher,
        status
      });
    }
  };

  render_modal = () => {
    if (this.state.loading) {
      return null;
    }
    return (
      <>
        <h5>Edit Session Details</h5>
        <div style={{ margin: '10px 0' }}>
          <CustomSingleSelect
            label="Change status"
            options={session_status_values}
            onChange={this.handle_modal_change}
            value={this.state.status || ''}
            style={{ width: '300px' }}
            name="status"
          />
        </div>
        {/* {this.state.status === 'CANCELLED'
					? 
					<div>
					<div style={{ marginBottom: '20px' }}>
						<CustomCheckBox
							label="Cancelled by student"
							checked={this.state.cancelled_by_student}
							onClick={this.toggle_cancel_student}
							readOnly
						/>
					</div>
					<div style={{ marginBottom: '20px' }}>
						<CustomCheckBox
							label="Cancelled by teacher"
							checked={this.state.cancelled_by_teacher}
							onClick={this.toggle_cancel_teacher}
						/>
					</div>
					</div>
					: null
					} */}
        {this.state.status === 'CANCELLED'
          ? cancel_options.map((option, index) => (
              <td key={index}>
                <CustomRadioButton
                  label={
                    option === 'cancelled_by_student'
                      ? 'Cancelled by Student'
                      : 'Cancelled by Teacher'
                  }
                  onRadioClick={() => this.toggle_cancel(option)}
                  selected={this.state[`${option}`]}
                />
              </td>
            ))
          : null}

        <CustomInput
          label="Enter Penalty"
          style={{ width: '300px' }}
          value={this.state.teacher_penalty}
          name="teacher_penalty"
          onChange={this.handle_modal_change}
          type="number"
        />
        <div style={{ margin: '10px 0' }}>
          <textarea
            value={this.state.teacher_penalty_reason}
            name="teacher_penalty_reason"
            onChange={this.handle_modal_change}
            style={{ width: '300px', height: '100px' }}
            maxLength="200"
            placeholder="Enter penalty reason (max 200 char)"
          />
        </div>
        <CustomCheckBox
          label="Ignore Rating"
          checked={this.state.ignore_rating}
          onClick={this.toggle_ignore_student}
        />
        {this.state.ignore_rating ? (
          <div style={{ margin: '10px 0' }}>
            <textarea
              value={this.state.ignore_rating_reason}
              name="ignore_rating_reason"
              onChange={this.handle_modal_change}
              style={{ width: '300px', height: '100px' }}
              maxLength="200"
              placeholder="Enter ignore rating reason (max 255 char)"
            />
          </div>
        ) : null}
        {this.state.modal_error ? (
          <p style={{ color: 'red' }}>* Teacher penaly should be greater or equal to 0</p>
        ) : null}
        {this.state.rating_error ? (
          <p style={{ color: 'red' }}>* Ignore reason cannot be empty</p>
        ) : null}
        <Button onClick={this.save_modal}>Save</Button>
      </>
    );
  };

  open_change_teacher = (uuid) =>
    this.setState({ change_session_uuid: uuid, change_teacher: true });

  close_change_teacher = () =>
    this.setState({
      change_session_uuid: '',
      change_teacher_id: '',
      change_reason: '',
      change_teacher: false
    });

  handle_teacher_change = (e) => this.setState({ change_teacher_id: e.target.value });

  handle_change_reasons = (e) => this.setState({ change_reason: e.target.value });

  submit_change_teacher = () => {
    const session_uuid = this.state.change_session_uuid;
    const teacher_uuid = this.state.change_teacher_id;
    const reason = this.state.change_reason;
    if (!!teacher_uuid && !!reason) {
      const url = get_api_url('change_teacher_for_session');
      const payload = { session_uuid, teacher_uuid, reason };
      post_api(url, payload, true)
        .then(() => {
          this.setState(
            {
              change_session_uuid: '',
              change_teacher_id: '',
              change_reason: '',
              change_teacher: false,
              loading: true
            },
            () => {
              this.load_data();
            }
          );
        })
        .catch((e) => {
          let err_message;
          try {
            console.log(e.response);
            err_message = e.response.data.message || e.response.data.reason.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
    } else {
      this.setState({ input_error: true });
    }
  };

  render_change_teacher = () => {
    const details = this.state.session_details;
    const teachers_list = this.state.teachers_list.map((teacher) => {
      const is_consultant =
        teacher.is_consultant_therapist_in_preferred_group == 1 ? ' (preferred consultant)' : '';
      return {
        value: teacher.teacher_uuid,
        label: `${teacher.name} ${is_consultant}`
      };
    });
    if (details.allow_teacher_replacement === 0) {
      return (
        <CustomModal show={this.state.change_teacher} close={this.close_change_teacher}>
          The student has opted not to replace the teacher. Please cancel the session or ask student
          to update the preference
        </CustomModal>
      );
    }
    return (
      <CustomModal show={this.state.change_teacher} close={this.close_change_teacher}>
        <h5>Change Teacher</h5>
        <div style={{ margin: '10px 0' }}>
          <CustomSingleSelect
            label="Select a teacher"
            options={teachers_list}
            onChange={this.handle_teacher_change}
            value={this.state.change_teacher_id || ''}
            style={{ width: '300px' }}
            data-session_id={this.state.change_session_uuid}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <textarea
            placeholder="enter a reason"
            value={this.state.change_reason || ''}
            data-session_id={this.state.change_session_uuid}
            onChange={this.handle_change_reasons}
            style={{ width: '300px', fontSize: '14px', lineHeight: '20px', height: '60px' }}
          />
        </div>
        <Button
          data-session_id={this.state.change_session_uuid}
          onClick={this.submit_change_teacher}
        >
          Submit
        </Button>
      </CustomModal>
    );
  };

  set_start_date = (date) => this.setState({ new_session_time: date });

  change_time = () => {
    const { teacher_uuid } = this.state.session_details;
    const sel_time = moment(this.state.new_session_time).format('YYYY-MM-DD HH:mm:ss');
    const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
    this.setState({ change_time_loading: true }, () => {
      const url = get_api_url('update_session_time');
      const payload = {
        session_uuid: this.state.session_details.session_uuid,
        start_time: epoch_time,
        teacher_uuid,
        student_uuid: this.state.session_details.student_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ change_time_loading: false, update_session_time: false });
          this.load_data();
          this.props.set_notification_variable(
            true,
            'success',
            'Session time updated successfully'
          );
        })
        .catch((e) => {
          this.setState({ update_session_time: false, change_time_loading: false });
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
    });
  };

  render_change_time = () => {
    const { session_details, new_session_time } = this.state;
    const old_time = moment(session_details.epoch_start_time)
      .tz(this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const new_time = moment(new_session_time).format('YYYY-MM-DD HH:mm:ss');
    return (
      <div>
        <h3>Change session time</h3>
        <DatePicker
          selected={new_session_time}
          onChange={(date) => this.set_start_date(date)}
          minDate={new Date()}
          maxDate={moment(new Date()).add(30, 'days').toDate()}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
        />
        <div>
          <Button disabled={old_time === new_time} onClick={this.change_time}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  open_update_time = () => {
    const curr_date = moment
      .tz(this.state.session_details.epoch_start_time, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const curr_time = moment(curr_date).toDate();
    this.setState({ update_session_time: true, new_session_time: curr_time });
  };

  close_update_time = () => this.setState({ update_session_time: false, new_session_time: null });

  close_update_duration = () => this.setState({ update_session_duration: false });

  close_qa_score = () => this.setState({ update_qa_score: false });

  change_duration = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('change_session_duration');
      const payload = {
        session_uuid: this.state.session_uuid,
        duration: this.state.new_duration,
        student_uuid: this.state.session_details.student_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ update_session_duration: false });
          this.load_data();
          this.props.set_notification_variable(true, 'success', 'Session duration updated');
        })
        .catch((e) => {
          let err_msg = 'Some error occured. Please contact dev team';
          try {
            const new_err_msg = e.response.data.message;
            if (new_err_msg) {
              err_msg = new_err_msg;
            }
          } catch {}
          this.props.set_notification_variable(true, 'error', err_msg);
          this.setState({ loading: false });
        });
    });

  render_change_duration = () => (
    <>
      <h5>Edit Session duration</h5>
      <div style={{ margin: '10px 0' }}>
        <CustomSingleSelect
          label="Change duration"
          options={session_duration_list}
          onChange={this.handle_modal_change}
          value={this.state.new_duration || ''}
          style={{ width: '300px' }}
          name="new_duration"
        />
      </div>
      <button onClick={this.change_duration}>Save</button>
    </>
  );

  change_qa_score = () => {
    this.setState({ change_qa_score: true }, () => {
      const url = get_api_url(
        `/v2/cms_user/sessions/${this.state.session_details.session_uuid}/update_qa_score`,
        '',
        true
      );
      const payload = {
        qa_score: parseFloat(this.state.qa_score.toFixed(2))
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ update_qa_score: false, change_qa_score: false });
          this.load_data();
          this.props.set_notification_variable(
            true,
            'success',
            'Session QA Score updated successfully'
          );
        })
        .catch((e) => {
          this.setState({ update_qa_score: false, change_qa_score: false });
          let err_message;
          try {
            err_message = e.response?.data?.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
    });
  };

  set_qa_score = (e) => {
    let { value } = e.target;
    value = Math.min(value, 100);
    value = parseFloat(value);

    if (value > 100) {
      newValue = 100;
    } else if (value < 0) {
      value = 0;
    }
    this.setState({ qa_score: value });
  };

  render_change_qa_score = () => (
    <>
      <h5>Change QA Score</h5>
      <div style={{ margin: '10px 0' }}>
        <CustomInput
          label="Enter QA Score"
          onChange={this.set_qa_score}
          name="qa_score"
          type="number"
          value={this.state.qa_score}
          style={{ width: '260px' }}
        />
      </div>
      <button onClick={this.change_qa_score}>Save</button>
    </>
  );

  party_by_teacher = () => this.setState({ by_teacher_student: 1 });

  party_by_student = () => this.setState({ by_teacher_student: 0 });

  change_session_to_party = () =>
    this.setState({ change_time_loading: true }, () => {
      const { party_participants, by_teacher_student, session_uuid } = this.state;
      const url = get_api_url('upgrade_yoga_to_private_group');
      const payload = {
        no_of_participants: party_participants + 1,
        session_uuid,
        from_teacher: by_teacher_student === 1 ? 1 : 0,
        from_student: by_teacher_student === 0 ? 1 : 0
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Party');
          const search = `?session_uuid=${session_uuid}`;
          const pathname = 'groupsessiondetails';
          this.props.history.push({ pathname, search });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ change_time_loading: false });
        });
    });

  render_party_open = () => {
    const { party_participants, by_teacher_student, party_confirm } = this.state;
    if (party_confirm) {
      return (
        <div>
          <h4>
            The user will be charged ${party_participants * 5} extra for {party_participants} more
            participants in this class. Please confirm if user is okay with it.
          </h4>
          <div>
            <Button onClick={this.change_session_to_party}>Confirm</Button>
            <Button onClick={this.toggle_party_open}>Cancel</Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h4>
          Adding more participant will cost $5 additional for every new participant, please confirm
          if user has agreed for it.
        </h4>
        <CustomInput
          label="Number of participants besides the host:"
          style={{ width: '300px' }}
          value={party_participants}
          name="party_participants"
          onChange={this.handle_modal_change}
          type="number"
        />
        <div style={{ marginBottom: '20px' }}>
          <CustomCheckBox
            label="On teacher’s request"
            checked={by_teacher_student === 1}
            onClick={this.party_by_teacher}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <CustomCheckBox
            label="On student’s request"
            checked={by_teacher_student === 0}
            onClick={this.party_by_student}
          />
        </div>
        <Button
          disabled={party_participants < 1}
          onClick={() => this.setState({ party_confirm: true })}
        >
          Submit
        </Button>
        <Button onClick={this.toggle_party_open}>Cancel</Button>
      </div>
    );
  };

  toggle_raw_data = () => this.setState({ show_raw_data: !this.state.show_raw_data });

  render_raw_data = () => {
    const { session_details, show_raw_data, raw_data_search } = this.state;
    if (show_raw_data) {
      const { raw_data } = session_details;
      const object_keys = Object.keys(raw_data).sort();
      const filtered_keys = object_keys.filter(
        (k) => k.toLowerCase().indexOf(raw_data_search.toLowerCase()) !== -1
      );
      return (
        <div style={{ height: '80vh' }}>
          <CustomInput
            label="Search key value"
            style={{ width: '500px' }}
            value={raw_data_search}
            name="raw_data_search"
            onChange={this.handle_modal_change}
          />
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
              <div className="ct-col ct-la ct-pl-10">Value</div>
            </div>
            {filtered_keys.map((key_val, index) => {
              const val = raw_data[key_val];
              if (typeof val !== 'object') {
                return (
                  <div className="ct-row" key={index + key_val}>
                    <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                      <HighlightSearch full_text={key_val} search_text={raw_data_search} />
                    </div>
                    <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">{val}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  render_survey_form = () => {
    const pre_session = this.state?.session_details?.pre_session;
    const post_session = this.state?.session_details?.post_session;
    return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3" />
              <th scope="col" className="px-6 py-3">
                Pre Session
              </th>
              <th scope="col" className="px-6 py-3">
                Post Session
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                Back Pain Intensity
              </th>
              <td className="px-6 py-4">{pre_session?.back_pain_intensity}</td>
              <td className="px-6 py-4">{post_session?.back_pain_intensity}</td>
            </tr>

            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                Energy Level
              </th>
              <td className="px-6 py-4">{pre_session?.energy_level}</td>
              <td className="px-6 py-4">{post_session?.energy_level}</td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                Energy Level Other
              </th>
              <td className="px-6 py-4">{pre_session?.energy_level_other}</td>
              <td className="px-6 py-4">{post_session?.energy_level_other}</td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                Created Date
              </th>
              <td className="px-6 py-4">
                {moment(pre_session?.created_date).tz(this.props.iana_timezone).format(day_format)}
              </td>
              <td className="px-6 py-4">
                {moment(post_session?.created_date).tz(this.props.iana_timezone).format(day_format)}
              </td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                Source
              </th>
              <td className="px-6 py-4">{pre_session?.source}</td>
              <td className="px-6 py-4">{post_session?.source}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const {
      change_time_loading,
      show_modal,
      update_session_time,
      update_session_duration,
      party_group_open,
      show_raw_data,
      penalty_open,
      teachers_loaded,
      show_survey_form,
      open_zoom_link_modal,
      update_qa_score
    } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Session Details</title>
        </Helmet>
        {change_time_loading ? <CustomLoading /> : null}
        <CustomModal show={show_modal} close={this.toggle_modal}>
          {this.render_modal()}
        </CustomModal>
        <CustomModal
          show={update_session_time}
          close={this.close_update_time}
          inner_style={{ minHeight: '50%' }}
        >
          {this.render_change_time()}
        </CustomModal>
        <CustomModal show={update_session_duration} close={this.close_update_duration}>
          {this.render_change_duration()}
        </CustomModal>
        <CustomModal show={update_qa_score} close={this.close_qa_score}>
          {this.render_change_qa_score()}
        </CustomModal>
        <CustomModal show={party_group_open} close={this.toggle_party_open}>
          {this.render_party_open()}
        </CustomModal>
        <CustomModal show={show_raw_data} close={this.toggle_raw_data}>
          {this.render_raw_data()}
        </CustomModal>
        <CustomModal show={penalty_open} close={this.close_penalty}>
          {this.render_penalty_popup()}
        </CustomModal>
        {teachers_loaded ? this.render_change_teacher() : null}
        {this.render_function()}
        <CustomModal show={show_survey_form} isFullWidth close={this.handle_survey_modal}>
          {this.render_survey_form()}
        </CustomModal>
        <CustomModal show={open_zoom_link_modal} close={this.close_zoom_link}>
          {this.render_zoon_link_modal()}
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSessionDetails);
